import React from "react"

const Sample = () => {
    return (
        <div className="sample-container is-justify-content-space-around">
            <div className="has-background-primary square">Sample1</div>
            <div className="has-background-dark square">Sample2</div>
            <div className="has-background-primary square">Sample3</div>
        </div>
    )
 }

export default Sample;